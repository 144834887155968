import React, { createContext, useContext, useState } from 'react';
import { RpcError } from '@dealroadshow/json-rpc-dispatcher';
import noop from 'lodash/noop';
import ErrorCodeHelper from '@finsight/error-codes';
import getRouter from '@/users/infrastructure/next/Router';

import { useSessionContext } from '@/users/application/Session/SessionContext';
import { getErrorMessage, getMessage, getMessageByErrorCode } from '@/Framework/Message/Mapper/getMessage';
import { AlertManager } from '@dealroadshow/uikit/core/components/Alert';
import { messageCodes } from '@/Framework/Message/messages';

import UsersSessionRepository, {
  INVESTOR_LOGIN_SSID_KEY,
} from '@/users/infrastructure/repository/SessionRepository';
import SessionRepository from '@/users/infrastructure/repository/session/SessionRepository';

import SessionStorageRepository from '@/Framework/browser/storage/SessionStorageRepository';
import { isDataroom } from '@/dataroom/domain/config';
import { DataroomTenant } from '@/dataroom/domain/vo/types/DataroomTenant';
import User from '@/users/domain/User';
import { IUser } from '@/users/domain/vo/User';
import config from '@/Framework/config';
import { getEvercallDashboardLoginCallbackUrl } from '@/evercall/application/dashboard/helpers';

import CallbackUrl from '@/Framework/url/CallbackUrl';

import usersUrl from '@/users/infrastructure/usersUrl';
import finsightWebUrl from '@/finsight/infrastructure/finsightWebUrl';
import dmPortalUrl from '@/dmPortal/infrastructure/url/dmPortalUrl';
import TenantConfig from '@/Framework/Tenant/TenantConfig';
import UserPermission from '@/users/domain/UserPermission';
import { useDIContext } from '@/Framework/DI/DIContext';

const useLogin = () => {
  const { container } = useDIContext();
  const usersSessionRepository = container.get<UsersSessionRepository>(UsersSessionRepository);
  const sessionRepository = container.get<SessionRepository>(SessionRepository);
  const sessionStorageRepository = container.get<SessionStorageRepository>(SessionStorageRepository);

  const {
    propagate,
    setIsTwoFactorAuthenticationRequired,
    getCurrentUser,
    getSession,
    setSsidCookie,
    session,
    currentUser,
  } = useSessionContext();

  const [loggingIn, setLoggingIn] = useState(false);
  const [transferring, setTransferring] = useState(false);

  /**
   * Login user and get ssid
   */
  async function login({
    email,
    password,
  }: {
    email: string,
    password: string,
  }) {
    const callbackUrl = CallbackUrl.fromGetParam() || dmPortalUrl.getUrl();
    const tenant = (new URLSearchParams(window.location.search).get('tenant') || config.tenant.dmPortal.code);
    const SAMLRequest = (new URLSearchParams(window.location.search).get('SAMLRequest'));
    const RelayState = (new URLSearchParams(window.location.search).get('RelayState'));

    setLoggingIn(true);
    try {
      let ssid: string;
      const payload = { email, password, tenant };
      switch (true) {
        case isDataroom(tenant):
          ssid = await sessionRepository.createDataroomSession(payload, tenant as DataroomTenant);
          break;
        default:
          ssid = await usersSessionRepository.createUserSession(payload);
          break;
      }

      await setSsidCookie(ssid);

      setLoggingIn(false);
      onLoginSuccess();
      await afterLogin({ ssid, callbackUrl, SAMLRequest, RelayState });
      return true;
    } catch (e) {
      setLoggingIn(false);
      return onLoginError(e, {
        email,
        callbackUrl: callbackUrl || window.location.href,
        tenant: tenant || TenantConfig.fromHostname().code,
      });
    }
  }

  async function loginInvestor<TData extends object>(
    {
      data,
      createSessionMethod,
      onSuccess,
      onError = noop,
      clearCache = false,
    }: {
      data: TData,
      createSessionMethod(data: TData): Promise<{ ssid: string }>,
      onSuccess({ ssid, user }: { ssid: string, user: IUser }): Promise<void>,
      onError?(e: any, data: TData, defaultOnLoginError: typeof onLoginError): void,
      clearCache?: boolean,
    },
  ) {
    setLoggingIn(true);
    try {
      const { ssid } = await createSessionMethod(data);
      // @ts-ignore
      const user = await usersSessionRepository.getCurrentUser(ssid, { clearCache });
      setLoggingIn(false);

      if (onSuccess) {
        onSuccess({ user, ssid });
      }
      return true;
    } catch (e) {
      setLoggingIn(false);
      if (onError !== noop) {
        return onError(e, data, onLoginError);
      }
      return onLoginError(e, data);
    }
  }

  /**
   * Login DRS investor
   */
  function loginInvestorDrs({
    email,
    entryCode,
    verificationToken,
  }: {
    email: string,
    entryCode: string,
    verificationToken?: string,
  }) {
    entryCode = entryCode.trim();
    email = email.toLowerCase();

    return loginInvestor(
      {
        data: {
          tenant: config.tenant.dealroadshow.code,
          email,
          params: {
            entryCode,
            verificationToken,
          },
        },
        createSessionMethod: usersSessionRepository.createCustomLogin,
        onSuccess: async ({ ssid, user }) => {
          if (User.isInvestorProfileFull(user)) {
            await setSsidCookie(ssid);
            window.location.href = `/e/${ entryCode }`;
            return;
          }

          sessionStorageRepository.clear();
          // Should go after on login success, since it clears session storage
          sessionStorageRepository.setItem(INVESTOR_LOGIN_SSID_KEY, ssid);
          getRouter().push(`/login/investor/complete_profile/e/${ entryCode }`);
        },
      },
    );
  }

  /**
   * Login Evercall investor
   */
  function loginInvestorEvercall({
    data: {
      email,
      dashboardId,
      token,
    },
    showCompleteProfileForm,
  }: {
    data: {
      email: string,
      dashboardId: string,
      token: string | null,
    },
    showCompleteProfileForm: ({ user }: { user: IUser }) => void,
  }) {
    const corporateEmail = email.toLowerCase();
    const dashboardIdTrimmed = dashboardId.trim();

    return loginInvestor(
      {
        data: {
          tenant: config.tenant.tenantEvercall.code,
          email: corporateEmail,
          params: {
            dashboardId: dashboardIdTrimmed,
            token,
          },
        },
        createSessionMethod: usersSessionRepository.createCustomLogin,
        onSuccess: async ({ ssid, user }) => {
          if (User.isInvestorProfileFull(user)) {
            return afterLogin(
              {
                ssid,
                callbackUrl: getEvercallDashboardLoginCallbackUrl(dashboardIdTrimmed),
              },
            );
          }

          sessionStorageRepository.clear();
          // Should go after on login success, since it clears session storage
          sessionStorageRepository.setItem(INVESTOR_LOGIN_SSID_KEY, ssid);
          showCompleteProfileForm({ user });
          return undefined;
        },
        onError: (e, data, defaultOnLoginError) => {
          if (
            e.error.code === ErrorCodeHelper.getCodeByName('EVERCALL_CHAT_INVALID_ARGUMENTS')
            && e.error?.data?.exception === 'SubscriptionDeniedException'
          ) {
            AlertManager.info(getMessage(
              messageCodes.EVERCALL_LOGIN_DENIED_DUE_TO_MULTIPLE_OPEN_TABS,
              { corporateEmail: email, dashboardId }),
            );
            throw e;
          } else {
            return defaultOnLoginError(e, data);
          }
        },
      },
    );
  }

  /**
   * Login Evercall OACC investor
   */
  function loginInvestorEvercallOacc(
    {
      data: {
        email,
        conferenceId,
      },
      showCompleteProfileForm,
      onLoginSucceed,
      onError,
    }: {
      data: {
        email: string,
        conferenceId: string,
      },
      showCompleteProfileForm: ({ user }: { user: IUser }) => void,
      onLoginSucceed: ({ ssid }: { ssid: string }) => Promise<void>,
      onError: (e: any) => void,
    },
  ) {
    const corporateEmail = email.toLowerCase();

    return loginInvestor(
      {
        clearCache: true,
        data: {
          tenant: config.tenant.tenantEvercall.code,
          email: corporateEmail,
          params: {
            conferenceId,
          },
        },
        createSessionMethod: usersSessionRepository.createCustomLogin,
        onSuccess: async ({ ssid, user }) => {
          if (User.isInvestorProfileFull(user)) {
            return onLoginSucceed({ ssid });
          }

          sessionStorage.clear();
          // Should go after on login success, since it clears session storage
          sessionStorage.setItem(INVESTOR_LOGIN_SSID_KEY, ssid);
          showCompleteProfileForm({ user });
          return undefined;
        },
        onError: (e) => {
          if (e?.error?.code === ErrorCodeHelper.getCodeByName('EVERCALL_OACC_ACCESS_IS_RESTRICTED')) {
            AlertManager.error(getMessageByErrorCode(ErrorCodeHelper.getCodeByName('SESSION_ACCESS_DENIED')));
          }
          if (e?.error?.code === ErrorCodeHelper.getCodeByName('EVERCALL_RESTRICT_ACCESS_DENIED')) {
            AlertManager.error(getMessageByErrorCode(ErrorCodeHelper.getCodeByName('EVERCALL_RESTRICT_ACCESS_DENIED')));
          }
          onError(e);
        },
      },
    );
  }

  async function loginSso({
    email,
  }: {
    email: string,
  }) {
    const callbackUrl = CallbackUrl.fromGetParam() || finsightWebUrl.getUrl();
    const SAMLRequest = (new URLSearchParams(window.location.search).get('SAMLRequest'));
    const RelayState = (new URLSearchParams(window.location.search).get('RelayState'));

    await usersSessionRepository.invalidateSession().catch(() => {});

    window.location.href = usersUrl.getSsoLoginUrl(email, callbackUrl, SAMLRequest, RelayState);
  }

  /**
   * Transfer existing session
   */
  async function transferExistingSession() {
    if (UserPermission.isTwoFactorAuthenticationRequired(session, currentUser)) {
      setIsTwoFactorAuthenticationRequired(true);
      return false;
    }

    setTransferring(true);
    const callbackUrl = CallbackUrl.fromGetParam() || dmPortalUrl.getUrl();
    const SAMLRequest = (new URLSearchParams(window.location.search).get('SAMLRequest'));
    const RelayState = (new URLSearchParams(window.location.search).get('RelayState'));
    const tenant = (new URLSearchParams(window.location.search).get('tenant') || config.defaultTenant);
    let sessionValid = false;
    try {
      switch (true) {
        case isDataroom(tenant):
          sessionValid = await sessionRepository.verifyDataroomSession(tenant as DataroomTenant);
          break;
        default:
          sessionValid = await usersSessionRepository.verifySession();
          break;
      }
    } catch (error) {
      if (error?.getCode() === ErrorCodeHelper.getCodeByName('TWO_FACTOR_CHECK_REQUIRED')) {
        setIsTwoFactorAuthenticationRequired(true);
        return false;
      }
      setTransferring(false);
    }

    if (!sessionValid) {
      setTransferring(false);
    }

    return propagate({ callbackUrl, SAMLRequest, RelayState });
  }

  function onLoginError(error: RpcError, params = {}) {
    if (
      error?.getCode() !== ErrorCodeHelper.getCodeByName('INVESTOR_VERIFICATION_EMAIL_SENT') &&
      error?.getCode() !== ErrorCodeHelper.getCodeByName('USER_ACTIVATION_EMAIL_SENT')
    ) {
      const errorMessage = getErrorMessage(error, params);
      AlertManager.error(errorMessage);
      throw error;
    }
    // Investor verification email auto sent
    // Activation email auto sent
    throw error;
  }

  function onLoginSuccess() {
    sessionStorage.clear();
  }

  async function afterLogin({
    ssid,
    callbackUrl,
    SAMLRequest,
    RelayState,
  }: {
    ssid?: string,
    callbackUrl?: string,
    SAMLRequest?: string,
    RelayState?: string,
  }) {
    const [currentUser, currentSession] = await Promise.all([
      getCurrentUser({ clearCache: true }),
      getSession({ clearCache: true }),
    ]);
    if (UserPermission.isTwoFactorAuthenticationRequired(currentSession, currentUser)) {
      setIsTwoFactorAuthenticationRequired(true);
    } else {
      propagate({ ssid, callbackUrl, SAMLRequest, RelayState });
    }
  }

  return {
    login,
    loginInvestorDrs,
    loginInvestorEvercall,
    loginInvestorEvercallOacc,
    loginSso,
    transferExistingSession,
    transferring,
    loggingIn,
  };
};

export const LoginContext = createContext<ReturnType<typeof useLogin>>(null);

export function useLoginContext() {
  const context = useContext(LoginContext);
  if (!context) {
    throw new Error('useLoginContext must be used within the LoginContext');
  }
  return context;
}

function LoginContextProvider({ children }: React.PropsWithChildren) {
  return (
    <LoginContext.Provider value={ useLogin() }>
      { children }
    </LoginContext.Provider>
  );
}

export default LoginContextProvider;
